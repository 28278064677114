@import '../styles/Colors.css';

.basic-button {
  width: 100%;
  padding-top: .5rem;
  padding-bottom: .5rem;
  /* background-color: var(--purple-color); */
  background-color: rgba(255, 255, 255, 0.30);
  color: var(--white-color);
  font-size: 1.2rem;
  border-radius: 80px;
  font-weight: 500;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vanish-animation {
  animation-name: vanish;
  animation-duration: 2s;
}

@keyframes vanish {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}