.store-create-menu {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 2rem;
}

.store-icon {
  font-size: 2.5rem;
  color: rgb(0, 0, 0);
}

.store-menu-button {
  align-items: center;
  color: rgb(254, 254, 254);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1rem;
  text-decoration: none;
  width: 10rem;
}

.store-back {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  width: 90%;
}

.store-back .icon {
  color: rgb(254, 254, 254);
  font-size: 2.5rem;
}
