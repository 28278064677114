.delete-modal-background {
    width: 400px;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    bottom: 0;
    left: auto;
    z-index: 1000;
}

.delete-modal-container {
    width: 400px;
    margin: 8%;
    border-radius: 20px;
    background-color: #eeeeee;
    position: relative;
    animation-name: slideUp;
    animation-duration: 0.2s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    padding: 5%;
    text-align: center;
    /* display: flex; */
}

@media only screen and (max-width: 400px) {
    .delete-modal-background {
        width: 100%;
    }

    .delete-modal-container {
        width: 100%;
    }
}

@keyframes slideUp {
    from {
        top: 100%;
    }

    to {
        top: 0;
    }
}