@import '../styles/Colors.css';

.modal-background {
  width: 400px;
  background-color: rgba(69, 65, 65, 0.722);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: auto;
}

.modal-container {
  width: 400px;
}

@media only screen and (max-width: 400px) {
  .modal-background {
    width: 100%;
  }

  .modal-container {
    width: 100%;
  }
}

.modal-close-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  margin-right: 1rem;
}

.modal-content {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.modal-content h2 {
  width: 14rem;
  text-align: center;
  margin-bottom: 2rem;
}

.modal-label {
  width: 70%;
  margin-bottom: 1rem;
}

.modal-close {
  background-color: rgba(255, 255, 255, 0);
}