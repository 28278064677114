.input-transparent::placeholder {
  color: white;
  opacity: 1;
}

.input-basic {
  background-color: #dcdcdc;
}

.input-basic::placeholder {
  color: black;
  font-size: 0.8rem;
}

.file-select {
  position: relative;
  display: inline-block;
  width: 100%;
}

.file-select::before {
  background-color: #1C1C1C;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  content: attr(data-content);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.file-select input[type='file'] {
  opacity: 0;
  height: 48px;
  display: inline-block;
  padding: 0.2rem 0.1rem;
}