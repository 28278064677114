.imgstore-title {
  font-size: 1rem;
  padding-bottom: 1rem;
}

.imgstore-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.imgstore-btn-container {
  display: flex;
  margin-top: 1rem;
  justify-content: center;
  width: 100%;
}

.imgstore-checkbox-wrapper {
  width: 90%;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.imgstore-btn-container2 {
  margin-top: 2rem;
}
