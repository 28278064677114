.audio-title {
  font-size: 1rem;
  padding-bottom: 1rem;
}

.audio-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.audio-btn-container {
  display: flex;
  margin-top: 1rem;
  justify-content: space-between;
  width: 97%;
}

.audio-checkbox-wrapper {
  width: 90%;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.audio-btn-container2 {
  margin-top: 2rem;
}

#inputID::placeholder {
  color: #ff0000;
  font-weight: 500;
}