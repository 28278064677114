.newHeader-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.newHeader-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.newHeader-backWrapper {
  width: 2rem;
}

.newHeader-content a {
  display: flex;
  align-items: center;
  justify-content: center;
}