.rhap_container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  line-height: 1;
  font-family: inherit;
  width: 100%;
  padding: 10px 0px !important;
  background-color: #fff;
  /* box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2); */
}

.rhap_container:focus:not(:focus-visible) {
  outline: 0;
}

.rhap_container svg {
  vertical-align: initial;
}

.rhap_header {
  margin-bottom: 10px;
}

.rhap_footer {
  margin-top: 5px;
}

.rhap_main {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.rhap_stacked .rhap_controls-section {
  margin-top: 8px;
}

.rhap_horizontal {
  flex-direction: row;
}

.rhap_horizontal .rhap_controls-section {
  margin-left: 8px;
}

.rhap_horizontal-reverse {
  flex-direction: row-reverse;
}

.rhap_stacked-reverse {
  flex-direction: column-reverse;
}

.rhap_stacked-reverse .rhap_controls-section {
  margin-bottom: 8px;
}

.rhap_progress-section {
  /* display: flex; */
  /* flex: 3 1 auto; */
  align-items: center;
}

.rhap_progress-container {
  margin: 0px 0px !important;
  display: flex;
  align-items: center;
  height: 20px;
  flex: 1 0 auto;
  align-self: center;
  margin: 0 calc(10px + 1%);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
}

.rhap_progress-container:focus:not(:focus-visible) {
  outline: 0;
}

.rhap_time {
  color: #fff;
  font-size: 13px;
  text-align: right;
  user-select: none;
  -webkit-user-select: none;
}

.rhap_current-time {
  text-align: left;
  margin-top: 25px;
  margin-left: 4px !important;
  position: absolute;
  font-size: 0.625rem;
}

.rhap_total-time {
  margin-top: 3px;
  margin-right: 4px !important;
  font-size: 0.625rem;
}

.rhap_progress-bar {
  margin: 0px 5px;
  box-sizing: border-box;
  position: relative;
  /* align-self: center;
    justify-content: center;
    align-items: center; */
  display: flex;
  z-index: 0;
  width: 100%;
  height: 5px;
  background-color: #dddddd;
  border-radius: 2px;
}

.rhap_progress-filled {
  height: 100%;
  position: absolute;
  z-index: 2;
  background-color: #fff;
  border-radius: 2px;
}

.rhap_progress-bar-show-download {
  background-color: rgba(221, 221, 221, 0.5);
}

.rhap_download-progress {
  margin: 0px 5px;
  height: 100%;
  position: absolute;
  z-index: 1;
  background-color: #a7a7a7;
  border-radius: 2px;
}

.rhap_progress-indicator {
  box-sizing: border-box;
  position: absolute;
  z-index: 3;
  width: 12px;
  height: 12px;
  margin-left: -4px;
  top: -3px;
  background: #fff;
  border-radius: 50px;
  box-shadow: rgba(134, 134, 134, 0.5) 0 0 5px;
}

.rhap_controls-section {
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
  align-items: center;
}

.rhap_additional-controls {
  display: flex;
  flex: 1 0 auto;
  align-items: center;
}

.rhap_repeat-button {
  font-size: 26px;
  width: 26px;
  height: 26px;
  color: #fff;
  margin-right: 6px;
}

.rhap_main-controls {
  flex: 0 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rhap_main-controls-button {
  margin: 0 3px;
  color: #fff;
  font-size: 35px;
  width: 35px;
  height: 35px;
}

.rhap_play-pause-button {
  font-size: 40px;
  width: 40px;
  height: 40px;
}

.rhap_volume-controls {
  display: flex;
  flex: 1 0 auto;
  justify-content: flex-end;
  align-items: center;
}

.rhap_volume-button {
  flex: 0 0 26px;
  font-size: 26px;
  width: 26px;
  height: 26px;
  color: #fff;
  margin-right: 6px;
}

.rhap_volume-container {
  display: flex;
  align-items: center;
  flex: 0 1 100px;
  user-select: none;
  -webkit-user-select: none;
}

.rhap_volume-bar-area {
  display: flex;
  align-items: center;
  width: 100%;
  height: 14px;
  cursor: pointer;
}

.rhap_volume-bar-area:focus:not(:focus-visible) {
  outline: 0;
}

.rhap_volume-bar {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 4px;
  background: #dddddd;
  border-radius: 2px;
}

.rhap_volume-indicator {
  box-sizing: border-box;
  position: absolute;
  width: 12px;
  height: 12px;
  margin-left: -6px;
  left: 0;
  top: -4px;
  background: #fff;
  opacity: 1;
  border-radius: 50px;
  box-shadow: rgba(134, 134, 134, 0.5) 0 0 3px;
  cursor: pointer;
}

.rhap_volume-indicator:hover {
  opacity: 0.9;
}

.rhap_volume-filled {
  height: 100%;
  position: absolute;
  z-index: 2;
  background-color: #fff;
  border-radius: 2px;
}

/* Utils */
.rhap_button-clear {
  background-color: transparent;
  border: none;
  padding: 0;
  overflow: hidden;
  cursor: pointer;
}

.rhap_button-clear:hover {
  opacity: 0.9;
  transition-duration: 0.2s;
}

.rhap_button-clear:active {
  opacity: 0.95;
}

.rhap_button-clear:focus:not(:focus-visible) {
  outline: 0;
}

.control_icon_div {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-self: center;
  margin: 20px 0 5px 0;
}

.control_icon {
  height: 40px;
  width: 40px;
}