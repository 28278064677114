/* CSS general purpose */
@import url('./styles/Colors.css');
.app {
  height: 100vh;
  width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.app-full {
  height: 100vh;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.gradient-bg {
  height: 100%;
  background: rgb(49, 19, 101);
  background: linear-gradient(
    14deg,
    rgba(49, 19, 101, 1) 0%,
    rgba(56, 192, 198, 1) 100%
  );

  padding-bottom: 3rem;
}

.white-bg {
  height: 100%;
  background-color: var(--white-color);
  padding-bottom: 3rem;
}

.text-center {
  text-align: center;
}

@media only screen and (max-width: 400px) {
  .app {
    width: 100%;
    height: 100%;
  }
}

.hide-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}

.container {
  padding-bottom: 8px;
  background-color: var(--white-color);
  overflow: scroll;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.container::-webkit-scrollbar {
  display: none;
}

.white-bg-container {
  background-color: white;
  height: 100%;
}

.blue-font {
  color: #2155c4;
}
