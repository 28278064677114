.image_wrapper_new_audio_container {
  width: 16rem;
  margin-top: 20px;
  height: 270px;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image_wrapper_new_audio {
  border: 1.5px solid #9491b4;
  border-radius: 12px;
  width: 16rem;
  height: 230px;
  object-fit: cover;
}

.new_audio_text {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  flex-direction: column;
}

.new_audio_title {
  font-size: 1rem;
}

.audio_player_timer_container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.new_audio_player_song_duration {
  font-size: x-small;
  font-weight: 600;
}

.light_text {
  font-weight: 300;
}

.new_audio_player_slider_div {
  margin-top: 50px;
}

.new_audio_player_slider {
  border-radius: 55px;
}

.playing_icon_audio {
  height: 60px;
  width: 60px;
  position: absolute;
  justify-self: center;
  align-self: center;

  /* margin-top: 90px; */
}

@media (max-width: 400px) {
  .image_wrapper_new_audio {
    border: 1.5px solid #9491b4;
    border-radius: 12px;
    width: 15rem;
    height: 15rem;
    object-fit: cover;
  }

  .new_audio_player_slider_div {
    margin-top: 70px;
  }
}

@media (min-width: 400px) {
  .image_wrapper_new_audio {
    border: 1.5px solid #9491b4;
    border-radius: 12px;
    width: 16rem;
    height: 16rem;
    object-fit: cover;
  }

  .new_audio_player_slider_div {
    margin-top: 80px;
  }
}

@media (min-width: 450px) {
  .image_wrapper_new_audio {
    border: 1.5px solid #9491b4;
    border-radius: 12px;
    width: 18rem;
    height: 18rem;
    object-fit: cover;
  }

  .new_audio_player_slider_div {
    margin-top: 110px;
  }
}