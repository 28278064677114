.landing-bg-image {
/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+63,000000+87&0+63,1+87 */
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 63%, rgba(0, 0, 0, 1) 87%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 63%, rgba(0, 0, 0, 1) 87%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 63%, rgba(0, 0, 0, 1) 87%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#000000', GradientType=0);
  /* IE6-9 */
}