@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'InterBold';
  src: local('InterBold'),
    url('./assets/fonts/Inter/Inter-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'InterSemiBold';
  src: local('InterSemiBold'),
    url('./assets/fonts/Inter/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'InterLight';
  src: local('InterLight'),
    url('./assets/fonts/Inter/Inter-Light.ttf') format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'InterRegular';
  src: local('InterRegular'),
    url('./assets/fonts/Inter/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'InterMedium';
  src: local('InterMedium'),
    url('./assets/fonts/Inter/Inter-Medium.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'InterThin';
  src: local('InterMedium'),
    url('./assets/fonts/Inter/Inter-Thin.ttf') format('truetype');
  font-weight: 100;
}

@font-face {
  font-family: 'InterExtraLight';
  src: local('InterMedium'),
    url('./assets/fonts/Inter/Inter-ExtraLight.ttf') format('truetype');
  font-weight: 200;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
}

button {
  border-width: 0;
}

h1 {
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
}