.audio_player_container {
  display: flex;
  flex-direction: row;
  margin: 20px 0;
}

.audio_player_left_container {
  flex-direction: column;
  width: 28%;
  height: 100;
  min-width: 70px;
  align-items: center;
  align-self: center;
}

.spacer {
  width: 4%;
}

.audio_player_image {
  height: 80px;
  width: 95%;
  object-fit: cover;
  border-radius: 12px;
  border: 2px solid #000;
  margin: 2px 0;
}

.audio_player_price_container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.audio_player_product_price {
  font-size: small;
  font-weight: 600;
  color: #0f00c1;
}

.audio_player_right_container {
  margin: 5px 0;
  width: 70%;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  flex-direction: column;
}

.audio_player_song_title {
  font-size: small;
  font-weight: bold;
}

.audio_player_song_description {
  font-size: medium;
  font-weight: 200;
  font-style: italic;
}
.audio_player_song_description p {
  font-size: 0.7rem;
  font-weight: 500;
  text-align: center;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-top: 0.5rem;
  font-style: normal;
}

.audio_player_song_duration {
  font-size: x-small;
  font-weight: 600;
}

.audio_player_slider {
  border-radius: 50px;
}

.audio_player_timer_container {
  display: flex;
  justify-content: space-between;
}

.light_text {
  font-weight: 300;
}

.playing_icon {
  height: 30px;
  width: 30px;
  position: absolute;
  margin-top: 28px;
  margin-left: 22px;
}
