.label-title {
  font-size: 20px;
  font-weight: bold;
}

.label-small-title {
  font-weight: bold;
  font-size: 16px;
}

.label-small {
  font-size: 12px;
  font-weight: 600;
}

.label-light {
  font-weight: 300;
}