.basic-input {
  width: 90%;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 32px;
  border-width: 0;
  font-size: 1rem;
  padding-left: 0.9rem;
}

.basic-input[type='date'] {
  -webkit-appearance: none;
  background-color: #fff;
  color: rgb(156, 165, 165);
  height: 1.3rem;
}

.basic-input[type='time'] {
  -webkit-appearance: none;
  background-color: #fff;
  color: rgb(156, 165, 165);
  height: 1.3rem;
}

.basic-input-wrapper {
  width: 100%;
  height: 3.3rem;
}
.input-margin {
  margin-bottom: 0.7rem;
}

.error-message-input {
  position: relative;
  height: 0.8rem;
  text-align: left;
  margin-block-start: 0;
  margin-block-end: 0;
  color: #ff6c67;
  font-weight: bold;
  width: 15rem;
  left: 1rem;
}

.checkboxWrapper input[type='checkbox'] {
  -webkit-appearance: none;
  appearance: none;
  width: 1.8em;
  height: 1.8em;
  border-radius: 0.3rem;
  margin-right: 0.5rem;
  border: 0.15em solid #ffffff;
  outline: none;
  cursor: pointer;
}

input.checked {
  position: relative;
}

input.checked::before {
  content: '✓';
  font-size: 1.5em;
  color: #fff;
  position: absolute;
  right: 1px;
  top: -5px;
}

.checkboxWrapper {
  display: flex;
  align-items: center;
}

.checkboxWrapper span {
  font-weight: 500;
  font-size: 0.76rem;
}

.file-select {
  position: relative;
  display: inline-block;
}

.file-select::before {
  background-color: rgb(68 61 165);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  content: attr(data-content);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.file-select input[type='file'] {
  opacity: 0;
  height: 32px;
  display: inline-block;
  padding: 0.2rem 0.1rem;
}

.small {
  width: 9.3rem;
}

.large {
  width: 21.2rem;
}

.textarea {
  height: 15rem;
  border-radius: 20px;
  width: 90%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding-left: 1rem;
  padding-top: 0.9rem;
  font-size: 1rem;
}

.textarea-wrapper {
  width: 100%;
  height: 18rem;
}

.transparent-input {
  width: 70%;
  height: 2rem;
  border-radius: 30px;
  border: rgba(255, 255, 255, 1) solid 0.2px;
  background-color: rgba(255, 255, 255, 0);
  padding-left: 1rem;
  color: rgba(255, 255, 255, 1);
}

@media (min-width: 425px) {
  .file-select input[type='file'] {
    width: 12rem;
  }
}

.small-input {
  width: 30px;
  border-radius: 4px;
  padding-left: 0.9rem;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
