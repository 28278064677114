.main_card {
  padding-top: 10px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
}

.audio_control_text_div {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  color: #fff;
  /* line-height: 48px; */
}

.audio_title {
  margin-bottom: 20px;
  font-size: 35px;
}

.audio_player_song_description {
  /* font-family: 'Inter' */
  font-weight: 500;
  font-style: normal;
  color: #fff;
  display: flex;
  flex-direction: column;
}

.audio_player_song_description_nd {
  /* font-family: 'Inter';
    font-style: normal; */
  /* font-weight: 200; */
  line-height: 20px;
  color: #fff;
}

.player_button_div {
  margin-top: 0.85rem;
  /* align-self: center; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.inter-font-family-bold {
  font-family: 'InterBold';
}

.inter-font-family-light {
  font-family: 'InterLight';
}

.inter-font-family-regular {
  font-family: 'InterRegular';
}

/* @font-face {
  font-family: 'InterBold';
  src: local('InterBold'),
    url('../../assets/fonts/Inter/Inter-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'InterLight';
  src: local('InterLight'),
    url('../../assets/fonts/Inter/Inter-Light.ttf') format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'InterRegular';
  src: local('InterRegular'),
    url('../../assets/fonts/Inter/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
} */