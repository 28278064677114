.text-item {
  padding-top: 24px;
  padding-bottom: 24px;
  text-align: center;
  border-bottom: 1px solid lightgrey;
}

.text-detail-container {
  padding-left: 32px;
  padding-right: 32px;
}

.text-detail-img-container {
  width: 100%;
  height: 40dvh;
  border: 2px solid black;
  border-radius: 16px;
}

.text-detail-img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text-detail {
  margin: 16px 8px;
}

.full_text_view {
  margin: 0 10px;
  height: 100%;
  z-index: 10000;
}

.full_text_main {
  height: 100%;
  z-index: 10000;
}

.text_full {
  text-align: justify;
  font-family: 'Times New Roman', Times, serif;
  white-space: pre-wrap;
  overflow-x: auto;
}