.list_image {
    height: 200px;
    width: 99%;
    object-fit: cover;
    border-radius: 12px;
    border: 2px solid #000;
    margin: 2px 0;
}

.spacer {
    width: 2%;
}

.list_2 {
    display: flex;
    width: 99%;
    flex-direction: row;
}

.image_list_2 {
    height: 200px;
}

.list_column_2 {
    float: left;
    width: 49%;
}

.list_3 {
    width: 100%;
    flex-direction: row;
    display: flex;
    /* flex: 1 */
}

.list_large_3 {
    width: 60%;
    height: 98%;
}

.list_column_3_large {
    float: left;
}

.image_large_3 {
    width: 96%;
    height: 200px;
}

.list_small_3 {
    width: 37%;
    flex-direction: column;
}

.image_list_3 {
    height: 94px;
    width: 99%;
}