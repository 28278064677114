.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 50vw;
    height: 50vh; */
    /* Adjust this according to your needs */
}

.breathing-logo {
    width: 100px;
    /* Adjust the size according to your needs */
    height: auto;
    animation: breathing 4s ease-in-out infinite;
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 85dvh;
    width: 100%;
}

@keyframes breathing {
    0% {
        transform: scale(1);
    }

    25% {
        transform: scale(1.2);
    }

    50% {
        transform: scale(0.8);
    }

    75% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(0.8);
    }
}