.textstore-title {
  font-size: 1rem;
  padding-bottom: 1rem;
}

.text-container {
  padding-bottom: 3.5rem;
}

.textstore-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.textstore-btn-container {
  display: flex;
  margin-top: 1rem;
  justify-content: center;
  width: 100%;
}

.textstore-checkbox-wrapper {
  width: 90%;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.textstore-btn-container2 {
  margin-top: 2rem;
}

.error-message-textArea {
  margin-block-start: 0;
  margin-block-end: 0;
  color: #ff6c67;
  position: relative;
  left: -4rem;
  bottom: 0.8rem;
  font-weight: bold;
}

@media (min-width: 425px) {
  .textstore-textarea {
    width: 87%;
  }
}
