.error-wrapper {
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.error-wrapper p {
  color: red;
  font-size: 1rem;
  margin-left: 0.5rem;
}
.error {
  display: flex;
  align-items: center;
  width: 90%;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  padding-left: 1rem;
  padding-right: 1rem;
}
