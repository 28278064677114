.video-item {
  padding: 8px;
  display: flex;
  align-items: center;
}

.video-item img {
  width: 8rem;
  height: 8rem;
  border-radius: 16px;
  object-fit: cover;
  border: 2px solid black;
}

.video-item-right {
  margin-left: 8px;
}

.video-player {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-player-container {
  position: relative;
  height: 100%;
}

.video-player-header {
  position: absolute;
  left: 8px;
  top: 0;
  z-index: 1000;
}