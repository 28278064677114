.btn-basic {
  padding: 4px 16px;
  border-radius: 32px;
  background-color: #5e77ff;
  color: white;
  font-size: 0.7em;
  cursor: pointer;
}

button.gradient-btn {
  height: 3.5rem;
  border-radius: 100rem;
  font-size: 0.8rem;
  font-weight: 700;
  width: 11rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  color: rgb(255, 255, 255);
  border: solid 5px transparent;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(101deg, rgb(26 176 97), rgb(81 220 203));
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px rgb(26 232 146) inset;
  cursor: pointer;
}

button.grd-disabled {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(101deg, #e2e3e9, #a9becf);

  box-shadow: 2px 1000px 1px #c9cacb inset !important;
}

.flat-button {
  padding: 12px;
  border-width: 1px;
  border-color: black;
  background-color: white;
  color: black;
  width: 100%;
  border-radius: 32px;
  font-size: 1.1em;
  font-weight: 500;
}

.gradient-button {
  border-radius: 100rem;
  font-size: 1.1rem;
  font-weight: 700;
  padding: 0.8rem 1rem;
  color: rgb(255, 255, 255);
  background-image: linear-gradient(
    14deg,
    rgba(49, 19, 101, 1) 0%,
    rgba(56, 192, 198, 1) 100%
  );
}

.blue-gradient-button {
  border-radius: 100rem;
  font-size: 0.8rem;
  font-weight: 700;
  width: 8rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  color: rgb(255, 255, 255);
  border: solid 5px transparent;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(101deg, #0f00c1, #0f00c191);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #0f00c1bf inset;
  cursor: pointer;
}
