@import '../../styles/Colors.css';

.payment-login-container {
  background-color: var(--purple-color);
}

.payment-login {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.code-login-input-group {
  margin-top: 32px;
}

.code-login-input-group input {
  margin-right: 8px;
}

.payment-product {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.payment-product-head {
  display: flex;
  align-items: center;
  margin-top: 64px;
}

.payment-product-head-img {
  width: 130px;
  height: 130px;
  object-fit: cover;
  border-radius: 16px;
  border: 2px solid #9491b4;
}

.payment-product-head-wave {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: scale-down;
  opacity: 0.7;
}

.payment-product-detail {
  margin-left: 24px;
  text-align: center;
}

.payment-options {
  margin-top: 16px;
}

.payment-option-item {
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px 16px;
  border: 1px solid #d9d9d9;
  border-radius: 20px;
  margin-top: 8px;
}

.payment-option-button {
  text-align: center;
  margin-top: 2.5rem;
}

.payment-method {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
  height: 100%;
}

.payment-method-head {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.payment-method-head img {
  width: 8rem;
  height: 8rem;
  border-radius: 16px;
  object-fit: cover;
  margin-right: 16px;
}

.payment-method-card {
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 8px;
}

.payment-method-card img {
  margin-right: 8px;
}

.payment-method-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.payment-method-body {
  height: 60%;
}

.icon_payment_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.payment_card_title {
  width: 90%;
  height: 25px;
}

.spacer {
  height: 10px;
}

.payment-radio {
  color: #0f00c1;
}

.card-price {
  display: flex;
  justify-content: space-between;
}

.payment-flow-info {
  font-size: 0.6rem;
  margin-block-start: 0em;
  margin-block-end: 0em;
  line-height: 12px;
  font-style: italic;
}

.payment-flow-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.add_card_button {
  margin-top: 15px;
}

.options_loader {
  justify-content: center;
  display: flex;
  margin: 20px;
}

.logo-container {
  width: 50%;
}
