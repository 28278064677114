.swiper-container {
    height: 100%;
    width: 100%;
    background-color: black;
}

.images {
    object-fit: cover;
}

/* Image Detail styles*/

.image-detail-img-container {
    width: 100%;
    height: 45dvh;
    border: 2px solid black;
    border-radius: 16px;
}

.image-detail-container {
    padding-left: 32px;
    padding-right: 32px;
}

.image_detail_image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 14px;
    filter: blur(5px);
}

/* Image Detail styles*/

.full_image {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.full_image_container {
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 1000;
}

.full_image_icon_container {
    position: absolute;
    top: 15px;
    left: 15px;
    padding: 5px;
    z-index: 100000;
    background-color: rgba(0, 0, 0, .6);
    border-radius: 50px;
    height: 25px;
    width: 25px;
    align-items: center;
    justify-content: center;

}