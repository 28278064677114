.app-modal-background {
    width: 400px;
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    display: flex;
    align-items: flex-end;
    top: 0;
    bottom: 0;
    left: auto;
    z-index: 1000;
}

.app-modal-container {
    width: 400px;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    background-color: #eeeeee;
    position: relative;
    animation-name: slideUp;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
}

@media only screen and (max-width: 400px) {
    .app-modal-background {
        width: 100%;
    }

    .app-modal-container {
        width: 100%;
    }
}

.divider {
    border-bottom: 1px solid rgba(217, 217, 217, 1);
}

@keyframes slideUp {
    from {
        top: 100%;
    }

    to {
        top: 0;
    }
}