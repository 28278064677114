.header {
  padding: 16px 0px;
  margin-left: 10px;
  position: relative;
}

.header #header-title {
  position: absolute;
  top: 22px;
  left: 32px;
  right: 32px;
  text-align: center;
}

.header-menu {
  display: flex;
  justify-content: flex-end;
  padding: 8px;
}
