.swiper-container {
  height: 100%;
  width: 100%;
  background-color: black;
}

.swiper-overlay {
  position: absolute;
  bottom: 32px;
  right: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 800;
}

.swiper-overlay img {
  width: 2em;
  height: 2.5em;
  border-radius: 10px;
  z-index: 800;
  object-fit: cover;
}

.swiper-overlay button {
  margin-top: 8px;
  z-index: 800;
}

.volume-overlay {
  position: absolute;
  bottom: 24px;
  left: 16px;
  z-index: 800;
}

.back-overlay {
  position: absolute;
  bottom: 24px;
  left: 130px;
  z-index: 800;
}


#root {
  height: 100svh;
  /* Set the height to cover the full viewport height */
  overflow: hidden;
  /* Hide the overflow to prevent scrolling during the flip */
  transition: transform 0.5s ease;
  /* Add a transition for a smooth flip effect */
}

/* Style for the flipped state */
.flipped {
  transform: scaleX(-1);
  /* Horizontal flip using scaleX() */
}