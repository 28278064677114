.default-icon {
  width: 2rem;
  height: 2rem;
}

.icon-container {
  align-items: center;
  background-color: rgb(254, 254, 254);
  border-radius: 99rem;
  display: flex;
  height: calc(3.2rem + 0.8vw);
  justify-content: center;
  margin-bottom: 0.5rem;
  width: calc(3.2rem + 0.8vw);
}

.icon-container2 {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);

  border-radius: 99rem;
  display: flex;
  height: calc(3.2rem + 0.8vw);
  justify-content: center;
  margin-bottom: 0.5rem;
  width: calc(3.2rem + 0.8vw);
}

.small-icon {
  width: 1.5rem;
}

.medium-icon {
  width: 2.8rem;
  height: 2.8rem;
}

.large-icon {
  width: 5.5rem;
  height: 5.5rem;
}

.medium-icon-container {
  align-items: center;
  background-color: rgb(254, 254, 254);
  border-radius: 99rem;
  display: flex;
  height: 4.5rem;
  justify-content: center;
  margin-bottom: 0.3rem;
  width: 4.5rem;
}

.square {
  border-radius: 0.8rem;
}

.circle {
  border-radius: 100%;
  border: 2px solid;
  border-color: white;
  padding: 3px;
}

.circle img {
  display: inline-block;
}

.small-icon {
  width: 1.3rem;
  height: 1.3rem;
}

@media (min-width: 425px) {
  .default-icon {
    width: 2.8rem;
    height: 2.8rem;
  }

  .icon-container {
    align-items: center;
    background-color: rgb(254, 254, 254);
    border-radius: 99rem;
    display: flex;
    height: 4.5rem;
    justify-content: center;
    margin-bottom: 0.3rem;
    width: 4.5rem;
  }

  .square {
    border-radius: 1rem;
  }
}
